import React, { useEffect, useState } from 'react';
import styles from '../../Pages/StudentDashboard/StudentDashboard.module.css';
import { API_URL, getToken } from '../../Helpers/constants';

export default function WebinarLiveTracking() {
    const [webinars, setWebinars] = useState([]);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalCount, setTotalCount] = useState(0);
    const token = getToken();

    useEffect(() => {
        const fetchWebinars = async () => {
            const myHeaders = new Headers();
            myHeaders.append('accept', 'application/json');
            myHeaders.append('Authorization', `Bearer ${token}`);

            const requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow',
            };

            try {
                const url = new URL(`${API_URL}/webinars/`);
                url.searchParams.append('page', page);
                url.searchParams.append('page_size', pageSize);

                const response = await fetch(url, requestOptions);
                const result = await response.json();

                // Ensure that the results are valid and an array
                setWebinars(Array.isArray(result) ? result : []);
                setTotalCount(result.count || 0); // Default to 0 if no count is available
            } catch (error) {
                console.error('Error fetching webinars:', error);
            }
        };

        fetchWebinars();
    }, [token, page, pageSize]);

    const handlePageChange = (newPage) => {
        setPage(newPage);
    };

    const handlePageSizeChange = (e) => {
        setPageSize(parseInt(e.target.value, 10));
        setPage(1);
    };

    return (
        <div className={styles.container}>
            {/* Page size selector */}
            <div className={styles.controls}>
                <label>
                    <span>Show</span>
                    <select value={pageSize} onChange={handlePageSizeChange}>
                        <option value={5}>5</option>
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                    </select>
                </label>
            </div>

            {/* Table displaying webinar data */}
            <table className={styles.table}>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Name</th>
                        <th>Job Profile Round</th>
                        <th>Time</th>
                        <th>Date</th>
                        <th>Is Finished</th>
                        <th>Joined/Total</th>
                        <th>Webinar Link</th>
                    </tr>
                </thead>
                <tbody>
                    {webinars.length > 0 ? (
                        webinars.map((webinar) => (
                            <tr key={webinar.id}>
                                <td>{webinar.id}</td>
                                <td>{webinar.name}</td>
                                <td>{webinar.job_profile_round}</td>
                                <td>{webinar.time}</td>
                                <td>{webinar.date}</td>
                                <td>{webinar.is_finished ? 'Yes' : 'No'}</td>
                                <td>{`${webinar.joined_students_count}/${webinar.joined_students_count +
                                    webinar.not_joined_students_count
                                    }`}</td>
                                <td
                                    style={{
                                        textAlign: 'center',
                                        fontSize: '1.15rem',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    <a
                                        href={webinar.link}
                                        target='_blank'
                                        rel='noopener noreferrer'
                                    >
                                        Join
                                    </a>
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan='8' style={{ textAlign: 'center' }}>
                                No webinars available
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>

            {/* Pagination */}
            {totalCount > pageSize && ( // Only show pagination if there are more items than the page size
                <div className={styles.pagination}>
                    {Array.from(
                        { length: Math.ceil(totalCount / pageSize) },
                        (_, i) => i + 1
                    ).map((pageNum) => (
                        <button
                            key={pageNum}
                            onClick={() => handlePageChange(pageNum)}
                            className={pageNum === page ? styles.active : ''}
                        >
                            {pageNum}
                        </button>
                    ))}
                </div>
            )}
        </div>
    );
}
