import {
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Snackbar,
  Tab,
  Tabs,
  TextField,
  Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import categoryIcon from "../../assets/category.svg";
import companyIcon from "../../assets/company.svg";
import dateIcon from "../../assets/date.svg";
import descriptionIcon from "../../assets/description.svg";
import priceIcon from "../../assets/price.svg";
import { API_URL, getToken } from '../../Helpers/constants';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import styles from './FreelanceOpp.module.css';
import dayjs from 'dayjs';

const FreelanceOpp = () => {
  const [opportunities, setOpportunities] = useState([]);
  const [myOpportunities, setMyOpportunities] = useState([]);
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);
  const [url, setUrl] = useState('');
  const [title, setTitle] = useState('');
  const [price, setPrice] = useState('');
  const [status, setStatus] = useState('');
  const [statusProofScreenshot, setStatusProofScreenshot] = useState(null);
  const [editingStatusId, setEditingStatusId] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [urlError, setUrlError] = useState('');
  const [titleError, setTitleError] = useState('');
  const [priceError, setPriceError] = useState('');
  const [categoryError, setCategoryError] = useState('');
  const [statusError, setStatusError] = useState('');
  const token = getToken();

  useEffect(() => {
    const myHeaders = new Headers();
    myHeaders.append("accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow"
    };

    fetch(`${API_URL}/freelance-opportunities/`, requestOptions)
      .then(response => response.json())
      .then(result => setOpportunities(result))
      .catch(error => setError(error));
  }, [token]);

  useEffect(() => {
    const myHeaders = new Headers();
    myHeaders.append("accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);

    fetch(`${API_URL}/freelance-jobs/`, {
      method: "GET",
      headers: myHeaders,
    })
      .then(response => response.json())
      .then(data => setMyOpportunities(data))
      .catch(error => setError(error));
  }, [token]);

  useEffect(() => {
    const myHeaders = new Headers();
    myHeaders.append("accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);

    fetch(`${API_URL}/categories/`, {
      method: "GET",
      headers: myHeaders,
    })
      .then(response => response.json())
      .then(result => setCategories(result))
      .catch(error => setError(error));
  }, [token]);

  const handleDialogOpen = () => setOpen(true);
  const handleDialogClose = () => {
    setOpen(false);
    setUrl('');
    setTitle('');
    setPrice('');
    setSelectedCategory('');
    setStatus('');
    setUrlError('');
    setTitleError('');
    setPriceError('');
    setCategoryError('');
    setStatusError('');
  };

  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
    setStatus('');
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    setStatusProofScreenshot(file ? file : null);
  };

  const handleAddJob = () => {
    let hasError = false;

    // Reset errors
    setUrlError('');
    setTitleError('');
    setPriceError('');
    setCategoryError('');
    setStatusError('');

    // Validate fields
    if (!url) {
      setUrlError("URL is required.");
      hasError = true;
    } else if (!url.startsWith("https://")) {
      setUrlError("URL must start with 'https://'.");
      hasError = true;
    }

    if (!title) {
      setTitleError("Job Title is required.");
      hasError = true;
    }

    if (!price || isNaN(price) || price <= 0) {
      setPriceError("Price must be a valid positive number.");
      hasError = true;
    }

    if (!selectedCategory) {
      setCategoryError("Category is required.");
      hasError = true;
    }

    if (!status) {
      setStatusError("Status is required.");
      hasError = true;
    }

    if (hasError) return; // Stop submission if validation fails

    const myHeaders = new Headers();
    myHeaders.append("accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("X-CSRFToken", "3OqpzgcoBBSaLcttFwNBvH11quLzhvNVOBNSJX5nwiWUoRJFWuR6mDTlCvUGBfuW");

    const formData = new FormData();
    formData.append('url', url);
    formData.append('status', status);
    formData.append('title', title);
    formData.append('price', price);
    formData.append('category', selectedCategory);
    formData.append('status_proof_screenshot', statusProofScreenshot || '');

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formData,
      redirect: "follow"
    };

    fetch(`${API_URL}/freelance-jobs/`, requestOptions)
      .then(async (response) => {
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(JSON.stringify(errorData));
        }
        return response.json();
      })
      .then(result => {
        setSnackbarOpen(true);
        setOpen(false);
        setOpportunities(result);
      })
      .catch(error => {
        const errorMessage = JSON.parse(error.message);
        setUrlError(errorMessage.url || '');
        setTitleError(errorMessage.title || '');
        setPriceError(errorMessage.price || '');
        setCategoryError(errorMessage.category || '');
        setStatusError(errorMessage.status || '');
        setOpen(true);
      });
  };

  const handleStatusChange = (event, opportunityId) => {
    const newStatus = event.target.value;
    updateStatus(opportunityId, newStatus);
    setEditingStatusId(null);
  };

  const statusTransitions = {
    proposal_submitted: ["proposal_submitted", 'interviewing', "in_progress", "completed", 'rejected'],
    interviewing: ["interviewing", 'in_progress', 'rejected', 'completed'],
    in_progress: ["in_progress", 'completed', 'rejected'],
    completed: ["completed"],
    rejected: ["rejected"],
    cv_submitted: ["cv_submitted", 'interviewing', 'rejected', 'passed'],
    interviewing_ft_pt: ["interviewing", 'rejected', 'passed'],
    passed: ["passed"],
  };

  const updateStatus = (opportunityId, newStatus) => {
    const myHeaders = new Headers();
    myHeaders.append("accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("X-CSRFToken", "9QeEo1XJDiStNNxyEBgs03ZtBRGN9cvcUDB7yIQIyZWdqsNKVzkXRZRNNSPUtWcd");

    const raw = JSON.stringify({
      "status": newStatus
    });

    const requestOptions = {
      method: "PATCH",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };

    fetch(`${API_URL}/freelance-jobs/${opportunityId}/`, requestOptions)
      .then(response => response.json())
      .then(result => {
        setMyOpportunities(prevState =>
          prevState.map(opportunity =>
            opportunity.id === opportunityId ? { ...opportunity, status: newStatus } : opportunity
          )
        );
        setSnackbarOpen(true);
      })
      .catch(error => setError(error));
  };

  const toggleEditStatus = (opportunityId) => {
    setEditingStatusId(opportunityId);
  };

  const renderStatusOptions = () => {
    if (selectedCategory === "1" || selectedCategory === "2") {
      return [
        <MenuItem key="cv_submitted" value="cv_submitted">CV Submitted</MenuItem>,
        <MenuItem key="interviewing" value="interviewing">Interviewing</MenuItem>,
        <MenuItem key="rejected" value="rejected">Rejected</MenuItem>,
        <MenuItem key="passed" value="passed">Passed</MenuItem>,
      ];
    } else if (selectedCategory === "3") {
      return [
        <MenuItem key="proposal_submitted" value="proposal_submitted">Proposal Submitted</MenuItem>,
        <MenuItem key="interviewing" value="interviewing">Interviewing</MenuItem>,
        <MenuItem key="rejected" value="rejected">Rejected</MenuItem>,
        <MenuItem key="completed" value="completed">Completed</MenuItem>,
      ];
    } else {
      return [
        <MenuItem key="select_first" disabled value="">
          Select a category first
        </MenuItem>
      ];
    }
  };

  const renderMyOpportunityCards = (data) => {
    return data.map((opportunity) => {
      const allowedTransitions = statusTransitions[opportunity.status] || [];

      return (
        <Card key={opportunity.id} className={styles.cardFreelanceJob}>
          <strong style={{ fontWeight: "bolder" }} className={styles.titleMyOpp}>{opportunity.title}</strong>
          <div className={styles.oppDrobdown}>
            {editingStatusId === opportunity.id ? (
              <TextField
                select
                label="Change Status"
                fullWidth
                value={opportunity.status}
                onChange={(event) => handleStatusChange(event, opportunity.id)}
                onClick={(event) => event.stopPropagation()}
                margin="dense"
              >
                {allowedTransitions.map((status) => (
                  <MenuItem key={status} value={status}>
                    {status.charAt(0).toUpperCase() + status.slice(1)}
                  </MenuItem>
                ))}
              </TextField>
            ) : (
              <Typography
                variant="body2"
                className={styles.statusBadge}
                onClick={() => toggleEditStatus(opportunity.id)}
              >
                {opportunity.status.charAt(0).toUpperCase() + opportunity.status.slice(1)} <KeyboardArrowDownIcon />
              </Typography>
            )}
          </div>
          <CardContent className={styles.CardContent}>
            <Typography variant="body2">
              <img src={dateIcon} alt="Date Icon" className={styles.icon} />
              <strong style={{ fontWeight: "bolder" }}>Created on:</strong> {dayjs(opportunity.created_on).format('D-M-YYYY')}
            </Typography>
            <Typography variant="body2">
              <img src={categoryIcon} alt="Category Icon" className={styles.icon} />
              {opportunity.category === "1" ? "Full Time" : opportunity.category === "2" ? "Part Time" : "Freelance"}
            </Typography>
            <Typography variant="body2">
              <img src={priceIcon} alt="Date Icon" className={styles.icon} />
              <strong style={{ fontWeight: "bolder" }}>Price:</strong> {opportunity.price}
            </Typography>
            <a className={styles.opportunitiesLink} href={opportunity.url} target="_blank" rel="noopener noreferrer">
              <Button variant="contained" color="primary">View Link</Button>
            </a>
          </CardContent>
        </Card>
      );
    });
  };

  const renderOpportunityCards = (data) => {
    return data.map((opportunity) => (
      <Card key={opportunity.id} className={styles.cardFreelanceJob}>
        <CardContent className={styles.CardContent}>
          <Typography variant="h6" className={styles.title}>
            <strong>{opportunity.title}</strong>
          </Typography>
          <Typography variant="body2">
            <img src={companyIcon} alt="Company Icon" className={styles.icon} />
            <strong style={{ fontWeight: "bolder" }}>Hiring Company:</strong> {opportunity.hiring_company}
          </Typography>
          <Typography variant="body2">
            <img src={categoryIcon} alt="Category Icon" className={styles.icon} />
            <strong style={{ fontWeight: "bolder" }}>Category:</strong> {opportunity.category}
          </Typography>
          <Typography variant="body2">
            <img src={descriptionIcon} alt="Description Icon" className={styles.icon} />
            <strong style={{ fontWeight: "bolder" }}>Description:</strong> {opportunity.description}
          </Typography>
          <Typography variant="body2">
            <img src={dateIcon} alt="Date Icon" className={styles.icon} />
            <strong style={{ fontWeight: "bolder" }}>Date:</strong> {opportunity.deadline}
          </Typography>
          <Typography variant="body2">
            <img src={priceIcon} alt="Price Icon" className={styles.icon} />
            <strong style={{ fontWeight: "bolder" }}>Price:</strong> {opportunity.price}
          </Typography>
          <a className={styles.opportunitiesLink} href={opportunity.url} target="_blank" rel="noopener noreferrer">
            <Button variant="contained" color="primary">View Link</Button>
          </a>
        </CardContent>
      </Card>
    ));
  };

  return (
    <div className={styles.firstContainer}>
      <div className={styles.container}>
        <div className={styles.tabsContainer}>
          <Tabs
            value={tabValue}
            onChange={(event, newValue) => setTabValue(newValue)}
            aria-label="tabs"
            TabIndicatorProps={{ style: { display: "none" } }}
            className={styles.infoTabs}
          >
            <Tab
              label="Jobs"
              className={`${styles.navLink} ${tabValue === 0 ? `${styles.activeTab} ${styles.activeTabMargin}` : ""}`}
            />
            <Tab
              label="My Jobs"
              className={`${styles.navLink} ${tabValue === 1 ? `${styles.activeTab} ${styles.activeTabMargin}` : ""}`}
            />
          </Tabs>
        </div>

        <div role="tabpanel" hidden={tabValue !== 0}>
          {error ? (
            <p className={styles.error}>Error fetching data: {error.message}</p>
          ) : (
            <div className={styles.cardContainer}>
              {opportunities.length > 0 ? renderOpportunityCards(opportunities) : <>No opportunities</>}
            </div>
          )}
        </div>
        <div role="tabpanel" hidden={tabValue !== 1}>
          {error ? (
            <p className={styles.error}>Error fetching data: {error.message}</p>
          ) : (
            <div className={styles.cardContainer}>
              {myOpportunities.length > 0 ? renderMyOpportunityCards(myOpportunities) : <>No opportunities</>}
            </div>
          )}
        </div>

        <Dialog className={styles.FreelanceJob} open={open} onClose={handleDialogClose}>
          <DialogTitle>Add Job</DialogTitle>
          <DialogContent>
            <TextField
              label="Job Title"
              type="text"
              fullWidth
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              error={!!titleError}
              helperText={titleError}
            />
            <TextField
              label="Job URL"
              className={styles.Url}
              type="url"
              fullWidth
              value={url}
              onChange={(e) => setUrl(e.target.value)}
              error={!!urlError}
              helperText={urlError}
            />
            <TextField
              select
              label="Category"
              fullWidth
              value={selectedCategory}
              onChange={handleCategoryChange}
              margin="dense"
              error={!!categoryError}
              helperText={categoryError}
            >
              {categories.map(category => (
                <MenuItem key={category.id} value={category.id.toString()}>
                  {category.name}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              select
              label="Status"
              fullWidth
              value={status}
              onChange={(e) => setStatus(e.target.value)}
              margin="dense"
              error={!!statusError}
              helperText={statusError}
            >
              {renderStatusOptions()}
            </TextField>
            <TextField
              label="Price"
              type="number"
              fullWidth
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              error={!!priceError}
              helperText={priceError}
            />
            <input
              type="file"
              accept="image/*"
              onChange={handleImageUpload}
              style={{ marginTop: '16px' }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleAddJob} color="primary">
              Add Job
            </Button>
          </DialogActions>
        </Dialog>

        <Snackbar
          open={snackbarOpen}
          autoHideDuration={2000}
          message="Job added successfully"
          onClose={() => setSnackbarOpen(false)}
        />
      </div>
      <aside className={styles.addJobLink}>
        <p className={styles.freelance_opportunities}>
          If you have freelance opportunities right now or<br /> if you completed one of them, add it now!
        </p>
        <Button className={styles.Add_Job_Link} variant="contained" color="primary" onClick={handleDialogOpen}>
          Add Gigs
        </Button>
      </aside>
    </div>
  );
};

export default FreelanceOpp;
