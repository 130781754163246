import React, { useState, useEffect } from "react";
import styles from "./AddWebinar.module.css";
import { API_URL, getToken } from "../../Helpers/constants";
import WebinarList from "../../Components/WebinarList/WebinarList";
import Modal from "../../Components/Modal/Modal";

function AddWebinar() {
  const token = getToken();
  const [jobProfiles, setJobProfiles] = useState([]);
  const [selectedProfile, setSelectedProfile] = useState("");
  const [newProfile, setNewProfile] = useState({
    code: "",
    name: "",
    community_link: "",
  });

  const [webinarData, setWebinarData] = useState({
    name: "",
    link: "",
    time: "",
    date: "",
  });
  const [error, setError] = useState("");
  const [minDate, setMinDate] = useState("");
  const [minTime, setMinTime] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(true);

  useEffect(() => {
    const fetchJobProfiles = async () => {
      const myHeaders = new Headers();
      myHeaders.append("accept", "application/json");
      myHeaders.append("Authorization", `Bearer ${token}`);

      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      try {
        const response = await fetch(
          `${API_URL}/job-profile-rounds/`,
          requestOptions
        );
        const result = await response.json();
        setJobProfiles(result);
        console.log('result', result)
      } catch (error) {
        console.error("Error fetching job profiles:", error);
      }
    };

    fetchJobProfiles();

    const now = new Date();
    const formattedDate = now.toISOString().split("T")[0];
    const formattedTime = now.toTimeString().split(" ")[0].slice(0, 5);

    setMinDate(formattedDate);
    setMinTime(formattedTime);
  }, [token]);

  const handleInputChange = (e) => {
    setNewProfile({ ...newProfile, [e.target.name]: e.target.value });
    setError("");
  };

  const handleWebinarChange = (e) => {
    setWebinarData({ ...webinarData, [e.target.name]: e.target.value });
    setError("");
  };

  const handlePostProfile = async (e) => {
    e.preventDefault();

    // Validation to check if the name and code match
    if (newProfile.code !== newProfile.name) {
      setError("The code and name must match.");
      return;
    }

    if (!newProfile.code || !newProfile.name || !newProfile.community_link) {
      setError("All fields are required.");
      return;
    }

    const myHeaders = new Headers();
    myHeaders.append("accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify(newProfile);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${API_URL}/job-profile-rounds/`,
        requestOptions
      );
      const result = await response.json();
      setJobProfiles((prev) => [...prev, result]);
      setNewProfile({ code: "", name: "", community_link: "" });
      setError(""); // Clear error if the submission is successful
    } catch (error) {
      console.error("Error posting new job profile round:", error);
    }
  };

  const handlePostWebinar = async (e) => {
    e.preventDefault();

    // URL validation
    const urlPattern = /^https:\/\/.*/;
    if (!urlPattern.test(webinarData.link)) {
      setError("The webinar link must start with https://");
      return;
    }

    if (
      !webinarData.name ||
      !webinarData.link ||
      !webinarData.date ||
      (!selectedProfile && !newProfile.name)
    ) {
      setError("All fields for the webinar are required.");
      return;
    }

    const myHeaders = new Headers();
    myHeaders.append("accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("Content-Type", "application/json");

    const profileRound = selectedProfile || newProfile.name;

    const raw = JSON.stringify({
      ...webinarData,
      job_profile_round: profileRound,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      // eslint-disable-next-line
      const response = await fetch(
        `${API_URL}/webinars/`,
        requestOptions
      );
      setWebinarData({ name: "", link: "", time: "", date: "" });

      // Reload the page after successfully adding the webinar
      window.location.reload();
    } catch (error) {
      console.error("Error posting webinar:", error);
    }
  };

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed); // Toggle the collapse state
  };

  return (
    <div>
      <div className={styles.header}>
        <button className={styles.addWebinarBtn} onClick={() => setShowModal(true)}>
          Add Webinar
        </button>
      </div>

      <WebinarList />

      <Modal show={showModal} onClose={() => setShowModal(false)}>
        <div className={styles.addWebinarContainer}>
          <h2>Add Webinar</h2>

          <label htmlFor="job-profile">Select Job Profile Round:</label>
          <select
            id="job-profile"
            value={selectedProfile}
            onChange={(e) => setSelectedProfile(e.target.value)}
          >
            <option value="">Select a profile</option>
            {jobProfiles.map((profile) => (
              <option key={profile.code} value={profile.code}>
                {profile.code}
              </option>
            ))}
          </select>

          {selectedProfile && (
            <p className={styles.selectedProfile}>
              <span>Selected Profile:</span> {selectedProfile}
            </p>
          )}

          {/* Collapsible Section for Add New Job Profile Round */}
          <h3 onClick={toggleCollapse} className={styles.collapsibleHeader}>
            <span className={styles.collapsibleTitle}>Add New Job Profile Round (Optional)</span>
            <span className={styles.toggleIcon}>
              {isCollapsed ? 'Expand +' : 'Collapse -'}
            </span>
          </h3>

          {/* Only show the form if it's not collapsed */}
          {!isCollapsed && (
            <form onSubmit={handlePostProfile} className={styles.form}>
              <label htmlFor="code">Code:</label>
              <input
                id="code"
                name="code"
                type="text"
                value={newProfile.code}
                onChange={handleInputChange}
                placeholder="Enter job profile code"
              />

              <label htmlFor="name">Name:</label>
              <input
                id="name"
                name="name"
                type="text"
                value={newProfile.name}
                onChange={handleInputChange}
                placeholder="Enter job profile name"
              />

              <label htmlFor="community_link">Community Link:</label>
              <input
                id="community_link"
                name="community_link"
                type="text"
                value={newProfile.community_link}
                onChange={handleInputChange}
                placeholder="Enter community link"
              />

              {error && <p className={styles.error}>{error}</p>}

              <button type="submit" className={styles.addWebinarSubmitBtn}>
                Add Job Profile Round
              </button>
            </form>
          )}

          <h3>Add Webinar Details</h3>
          <form onSubmit={handlePostWebinar} className={styles.form}>
            <label htmlFor="webinar-name">Webinar Name:</label>
            <input
              id="webinar-name"
              name="name"
              type="text"
              value={webinarData.name}
              onChange={handleWebinarChange}
              placeholder="Enter webinar name"
            />

            <label htmlFor="webinar-link">Webinar Link:</label>
            <input
              id="webinar-link"
              name="link"
              type="text"
              value={webinarData.link}
              onChange={handleWebinarChange}
              placeholder="Enter webinar link"
            />

            <label htmlFor="webinar-time">Time:</label>
            <input
              id="webinar-time"
              name="time"
              type="time"
              min={minTime}
              value={webinarData.time}
              onChange={handleWebinarChange}
            />

            <label htmlFor="webinar-date">Date:</label>
            <input
              id="webinar-date"
              name="date"
              type="date"
              min={minDate}
              value={webinarData.date}
              onChange={handleWebinarChange}
            />

            {error && <p className={styles.error}>{error}</p>}

            <button type="submit" className={styles.addWebinarSubmitBtn}>
              Add Webinar
            </button>
          </form>
        </div>
      </Modal>
    </div>
  );
}

export default AddWebinar;
