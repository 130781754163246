import { Box, Button, MenuItem, Modal, Select, TextField } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import React, { useEffect, useState } from 'react';
import { API_URL, getToken } from '../../Helpers/constants';
import styles from './CreateTask.module.css';

function CreateTask() {
  const [tasks, setTasks] = useState([]);
  const [open, setOpen] = useState(false);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [deadline, setDeadline] = useState(new Date());
  const [jobProfileRounds, setJobProfileRounds] = useState([]); // To store the list of job profile rounds
  const [selectedJobProfileRound, setSelectedJobProfileRound] = useState(''); // To store the selected job profile round
  const token = getToken();

  useEffect(() => {
    fetchTasks();
    fetchJobProfileRounds(); // Fetch the job profile rounds when the component loads
    // eslint-disable-next-line
  }, []);

  const fetchTasks = () => {
    const myHeaders = new Headers();
    myHeaders.append('accept', 'application/json');
    myHeaders.append('Authorization', `Bearer ${token}`);
    myHeaders.append('X-CSRFToken', '2Wlr6tQbvfWPZ7AvBEoK7vbl44ZDDdJ4FEN0p4gpjWwUuxJE721PzbVLNVoDlAsq');

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };

    fetch(`${API_URL}/tasks/`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (Array.isArray(result)) {
          setTasks(result);
        } else {
          console.error("Unexpected data structure:", result);
          setTasks([]); // Set tasks to an empty array if result is not an array
        }
      })
      .catch((error) => console.error('Error fetching tasks:', error));
  };

  const fetchJobProfileRounds = () => {
    const myHeaders = new Headers();
    myHeaders.append('accept', 'application/json');
    myHeaders.append('Authorization', `Bearer ${token}`);
    myHeaders.append('X-CSRFToken', '2Wlr6tQbvfWPZ7AvBEoK7vbl44ZDDdJ4FEN0p4gpjWwUuxJE721PzbVLNVoDlAsq');

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };

    fetch(`${API_URL}/job-profile-rounds/`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (Array.isArray(result)) {
          setJobProfileRounds(result); // Store the job profile rounds in state
        } else {
          console.error("Unexpected data structure:", result);
          setJobProfileRounds([]);
        }
      })
      .catch((error) => console.error('Error fetching job profile rounds:', error));
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSubmit = () => {
    const myHeaders = new Headers();
    myHeaders.append('accept', 'application/json');
    myHeaders.append('Authorization', `Bearer ${token}`);
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('X-CSRFToken', '2Wlr6tQbvfWPZ7AvBEoK7vbl44ZDDdJ4FEN0p4gpjWwUuxJE721PzbVLNVoDlAsq');

    const raw = JSON.stringify({
      job_profile_round: selectedJobProfileRound, // Use the selected job profile round
      name: name,
      description: description,
      deadline: deadline.toISOString(),
    });

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    };

    fetch(`${API_URL}/tasks/`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setOpen(false);
        fetchTasks(); // Refresh tasks
      })
      .catch((error) => console.error(error));
  };

  return (
    <div className={styles.container}>
      {/* Button to open the modal */}
      <div className={styles.divAddNewTask}>
        <Button variant="contained" color="primary" onClick={handleOpen}>
          Add New Task
        </Button>
      </div>

      <h1 className={styles.taskList}>Tasks List</h1>
      <div className={styles.meetingListContainer}> {/* Reuse the same container style */}
        <div className={styles.meetingCards}> {/* Reuse the same grid style */}
          {tasks.length === 0 ? (
            <p>No tasks available.</p>
          ) : (
            tasks.map((task) => (
              <div key={task.id} className={styles.meetingCard}> {/* Reuse the card style */}
                <h3 className={styles.meetingName}>Task Name:{task.name}</h3> {/* Task name */}
                <p className={styles.meetingInstructor}><strong>Description:</strong> {task.description}</p> {/* Task description */}
                <p className={styles.meetingDate}><strong>Job Profile Round:</strong> {task.job_profile_round}</p> {/* Job Profile Round */}
                <p className={styles.meetingDate}><strong>Deadline:</strong> {new Date(task.deadline).toLocaleDateString()}</p> {/* Task Deadline */}
              </div>
            ))
          )}
        </div>
      </div>

      {/* Modal for adding a new task */}
      <Modal open={open} onClose={handleClose}>
        <Box className={styles.modalBox}>
          <h2>Add a New Task</h2>

          {/* Dropdown for selecting job profile round */}
          <Select
            fullWidth
            value={selectedJobProfileRound}
            onChange={(e) => setSelectedJobProfileRound(e.target.value)}
            displayEmpty
            margin="normal"
          >
            <MenuItem value="" disabled>
              Select Job Profile Round
            </MenuItem>
            {jobProfileRounds.map((round) => (
              <MenuItem key={round.code} value={round.code}>
                {round.code}
              </MenuItem>
            ))}
          </Select>
          <TextField
            fullWidth
            label="Task Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            margin="normal"
          />
          <TextField
            fullWidth
            label="Description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            margin="normal"
          />
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
              label="Deadline"
              value={deadline}
              onChange={(newValue) => setDeadline(newValue)}
              renderInput={(params) => <TextField {...params} fullWidth margin="normal" />}
            />
          </LocalizationProvider>



          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Submit Task
          </Button>
        </Box>
      </Modal>
    </div>
  );
}

export default CreateTask;
