import React, { useState, useEffect } from 'react';
import {
    Tabs,
    Tab,
    Box,
    Card,
    CardContent,
    Typography,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from '@mui/material';
import { API_URL, getToken } from '../../Helpers/constants';
import styles from './MeetingInstructor.module.css';
import { RadioGroup, FormControlLabel, Radio, TextField } from '@mui/material';


const MeetingInstructor = () => {
    const [meetings, setMeetings] = useState([]);
    const [attendances, setAttendances] = useState([]);
    // eslint-disable-next-line
    const [selectionMade, setSelectionMade] = useState({});
    const [feedbackData, setFeedbackData] = useState({});
    const [tabValue, setTabValue] = useState(0);
    const [openDialog, setOpenDialog] = useState(false); // Popup state
    const [currentAttendanceId, setCurrentAttendanceId] = useState(null);
    const [joinChoice, setJoinChoice] = useState(''); // Radio button value for join choice
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false); // Confirmation popup state
    const [deleteMeetingId, setDeleteMeetingId] = useState(null); // Store the meeting to delete

    const token = getToken();

    useEffect(() => {
        fetchMeetings();
        fetchAttendances();
        // eslint-disable-next-line
    }, [token]);

    const fetchMeetings = async () => {
        const myHeaders = new Headers({
            "accept": "application/json",
            "Authorization": `Bearer ${token}`,
        });

        const requestOptions = {
            method: "GET",
            headers: myHeaders,
        };

        try {
            const response = await fetch(`${API_URL}/meetings/`, requestOptions);
            const result = await response.json();
            setMeetings(result);
        } catch (error) {
            console.error("Error fetching meetings:", error);
        }
    };

    const fetchAttendances = async () => {
        const myHeaders = new Headers({
            "accept": "application/json",
            "Authorization": `Bearer ${token}`,
        });

        const requestOptions = {
            method: "GET",
            headers: myHeaders,
        };

        try {
            const response = await fetch(`${API_URL}/attendances/`, requestOptions);
            const result = await response.json();
            setAttendances(result);
        } catch (error) {
            console.error("Error fetching attendances:", error);
        }
    };

    // Handle tab changes
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    // Handle dialog open
    const handleOpenDialog = (attendanceId) => {
        setCurrentAttendanceId(attendanceId);
        setOpenDialog(true);
    };

    // Handle dialog close
    const handleCloseDialog = () => {
        setOpenDialog(false);
        setJoinChoice('');
        setFeedbackData((prev) => ({ ...prev, [currentAttendanceId]: { feedback: '' } }));
    };

    // Handle feedback and attendance submission
    const handleSubmitFeedback = async () => {
        if (!joinChoice) {
            alert("Please select whether the student attended the meeting.");
            return;
        }

        const myHeaders = new Headers();
        myHeaders.append("accept", "application/json");
        myHeaders.append("Authorization", `Bearer ${token}`);
        myHeaders.append("Content-Type", "application/json");

        const feedback = feedbackData[currentAttendanceId]?.feedback || '';

        const raw = JSON.stringify({
            "joined": joinChoice === 'yes',
            "feedback": joinChoice === 'yes' ? feedback : '',
        });

        const requestOptions = {
            method: "PUT",
            headers: myHeaders,
            body: raw,
        };

        try {
            const response = await fetch(`${API_URL}/attendances/${currentAttendanceId}/`, requestOptions);
            const result = await response.json();
            console.log("Attendance status updated with feedback:", result);

            setAttendances((prevAttendances) =>
                prevAttendances.map((attendance) =>
                    attendance.id === currentAttendanceId
                        ? { ...attendance, joined: joinChoice === 'yes', feedback }
                        : attendance
                )
            );
            handleCloseDialog(); // Close the popup after submission
        } catch (error) {
            console.error("Error updating attendance:", error);
        }
    };

    // Handle feedback input changes
    const handleFeedbackChange = (value) => {
        setFeedbackData((prev) => ({
            ...prev,
            [currentAttendanceId]: {
                feedback: value,
            }
        }));
    };

    // Handle meeting deletion confirmation
    const handleDeleteMeetingClick = (meetingId) => {
        setDeleteMeetingId(meetingId); // Store the meeting ID to delete
        setOpenConfirmDialog(true); // Open confirmation dialog
    };

    // Confirm meeting deletion
    const confirmDeleteMeeting = async () => {
        const myHeaders = new Headers({
            "accept": "application/json",
            "Authorization": `Bearer ${token}`,
            "X-CSRFToken": "24nnkdpQLcbmVUH7CU8gxl1k6XZBV7qMURjxWVXgi2s8iVIXemboxZLSmP0SAzOT",
        });

        const requestOptions = {
            method: "DELETE",
            headers: myHeaders,
        };

        try {
            const response = await fetch(`${API_URL}/meetings/${deleteMeetingId}/delete_unreserved_meeting/`, requestOptions);
            if (response.ok) {
                console.log(`Meeting ${deleteMeetingId} deleted successfully.`);
                setMeetings((prevMeetings) => prevMeetings.filter((meeting) => meeting.id !== deleteMeetingId));
            } else {
                console.error(`Failed to delete meeting ${deleteMeetingId}.`);
            }
        } catch (error) {
            console.error(`Error deleting meeting ${deleteMeetingId}:`, error);
        } finally {
            setOpenConfirmDialog(false); // Close the confirmation dialog
            setDeleteMeetingId(null); // Reset the meeting ID after deletion
        }
    };

    const renderMeetings = (meetingsList, showAttendances = false, allowDelete = false) => {
        return (
            <Box className={styles.detailsSection}>
                {meetingsList.length > 0 ? (
                    meetingsList.map((meeting) => (
                        <Card key={meeting.id} className={styles.detailCard}>
                            <CardContent>
                                <Typography className={styles.subtitle}>
                                    <strong>Title:</strong> {meeting.title}
                                </Typography>
                                <Typography><strong>Date:</strong> {meeting.date}</Typography>
                                <Typography><strong>Start Time:</strong> {meeting.start_time}</Typography>
                                <Typography><strong>End Time:</strong> {meeting.end_time}</Typography>

                                {/* Show Delete Button if in Free Slots */}
                                {allowDelete && (
                                    <Button
                                        variant="contained"
                                        color="error"
                                        onClick={() => handleDeleteMeetingClick(meeting.id)}
                                        className={styles.deleteButton}
                                    >
                                        Delete Meeting
                                    </Button>
                                )}

                                {showAttendances && (
                                    <ul>
                                        {attendances
                                            .filter((attendance) => attendance.meeting.id === meeting.id)
                                            .map((attendance) => (
                                                <React.Fragment key={attendance.id}>
                                                    <p className={styles.attendancesTitle}>
                                                        Attendee-Name: <span className={styles.attendanceEmail}>{attendance.student_name}</span>
                                                    </p>
                                                    <p className={styles.attendancesTitle}>
                                                        Attendee-Email: <span className={styles.attendanceEmail}>{attendance.student_email}</span>
                                                    </p>
                                                    <p className={styles.attendancesTitle}>
                                                        Attendee-Phone: <span className={styles.attendanceEmail}>{attendance.phone}</span>
                                                    </p>
                                                    <li className={styles.attendanceItem}>
                                                        {!attendance.joined && !selectionMade[attendance.id] && (
                                                            <div className={styles.buttonGroup}>
                                                                <Button
                                                                    variant="contained"
                                                                    color="primary"
                                                                    onClick={() => handleOpenDialog(attendance.id)}
                                                                    className={styles.attendanceButton}
                                                                >
                                                                    Attendance & Feedback
                                                                </Button>
                                                            </div>
                                                        )}
                                                    </li>
                                                </React.Fragment>
                                            ))}
                                    </ul>
                                )}
                            </CardContent>
                        </Card>
                    ))
                ) : (
                    <p>No coaching sessions available.</p>
                )}
            </Box>
        );
    };

    const freeSlots = meetings.filter((meeting) => !meeting.is_reserved);
    const reservedSlots = meetings.filter((meeting) => meeting.is_reserved);

    return (
        <div className={styles.meetingInstructorContainer}>
            <h1 className={styles.title}>Coach Coaching Sessions</h1>

            <Tabs
                value={tabValue}
                onChange={handleTabChange}
                sx={{ marginBottom: '25px', padding: '20px' }}
                textColor="primary"
                indicatorColor="primary"
                TabIndicatorProps={{ style: { display: 'none' } }}
            >
                <Tab
                    label="Free Slots"
                    sx={{
                        backgroundColor: tabValue === 0 ? '#aebdc5' : '#f0f0f0',
                        color: tabValue === 0 ? 'white !important' : 'black',
                        borderRadius: '8px 8px 0 0',
                        padding: '10px 20px',
                        fontWeight: 'bold',
                    }}
                />
                <Tab
                    label="Reserved Meetings"
                    sx={{
                        backgroundColor: tabValue === 1 ? '#aebdc5' : '#f0f0f0',
                        color: tabValue === 1 ? 'white !important' : 'black',
                        borderRadius: '8px 8px 0 0',
                        padding: '10px 20px',
                        fontWeight: 'bold',
                    }}
                />
            </Tabs>

            {/* Render content based on active tab */}
            {tabValue === 0 && renderMeetings(freeSlots, false, true)}  {/* Show delete button for free slots */}
            {tabValue === 1 && renderMeetings(reservedSlots, true, false)}  {/* No delete button for reserved slots */}

            {/* Feedback and Join Popup */}
            <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogTitle>Feedback and Join</DialogTitle>
                <DialogContent>
                    <h1>Has this student attended the meeting?</h1>
                    <RadioGroup
                        aria-label="join-choice"
                        value={joinChoice}
                        onChange={(e) => setJoinChoice(e.target.value)}
                        className={styles.radioButtons}
                    >
                        <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                        <FormControlLabel value="no" control={<Radio />} label="No" />
                    </RadioGroup>

                    {joinChoice === "yes" && (
                        <TextField
                            label="Feedback"
                            variant="outlined"
                            fullWidth
                            multiline
                            rows={3}
                            value={feedbackData[currentAttendanceId]?.feedback || ''}
                            onChange={(e) => handleFeedbackChange(e.target.value)}
                            sx={{ marginTop: 2 }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={handleSubmitFeedback} color="primary">
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Confirmation Popup */}
            <Dialog open={openConfirmDialog} onClose={() => setOpenConfirmDialog(false)}>
                <DialogTitle>Confirm Delete</DialogTitle>
                <DialogContent>
                    <Typography>Are you sure you want to delete this meeting?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenConfirmDialog(false)} color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={confirmDeleteMeeting} color="primary">
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default MeetingInstructor;