import PersonIcon from '@mui/icons-material/Person';
import { Menu, MenuItem } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { getRole, getToken } from '../../Helpers/constants';
import styles from './SubNavbar.module.css';

function SubNavbar() {
  const [email, setEmail] = useState('');
  const [anchorEl, setAnchorEl] = useState(null); // State for dropdown menu
  const token = getToken();
  const role = getRole();


  useEffect(() => {
    const myHeaders = new Headers();
    myHeaders.append("accept", "application/json");
    myHeaders.append("X-CSRFToken", "BoxKNWiVfvhecV7czR1549o9zjixc0dpr3SQxbmiHjXUGgQpf3kjT3zpyHzFF8mJ");
    myHeaders.append("Authorization", `Bearer ${token}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow"
    };

    fetch("https://elharefaback.depi.eyouthlearning.com/api/accounts/profile/", requestOptions)
      .then(response => response.json())
      .then(result => {
        if (role === "staff" && result?.email) {
          setEmail(result.email);
        } else {
          setEmail(result?.name || result?.user?.email || result?.email || "Unknown User");
          if (result.code === "token_not_valid") {
            handleLogout()


          }
        }
      })
      .catch(error => console.error(error));
    // eslint-disable-next-line
  }, [token, role]);

  // Handle click for opening the menu
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Handle close for closing the menu
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // Handle logout action
  const handleLogout = () => {
    const myHeaders = new Headers();
    myHeaders.append("accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("X-CSRFToken", "BoxKNWiVfvhecV7czR1549o9zjixc0dpr3SQxbmiHjXUGgQpf3kjT3zpyHzFF8mJ");

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow"
    };

    fetch("https://elharefaback.depi.eyouthlearning.com/api/accounts/logout/", requestOptions)
      .then(response => response.text())
      .then(result => {
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('role');
        window.location.href = "/login";
      })
      .catch(error => console.error(error));
  };

  return (
    <div className={styles.subNavbar}>
      <div className={styles.greeting} onClick={handleMenuClick}>
        <PersonIcon />
        <span>Hello {email}</span>
      </div>

      {/* Dropdown Menu */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        keepMounted
        className={styles.dropdownMenu}
      >
        <MenuItem onClick={handleLogout}>Sign out</MenuItem>
      </Menu>
    </div>
  );
}

export default SubNavbar;
