import React, { useState, useEffect } from 'react';
import styles from './TasksInstructor.module.css';
import { getToken } from '../../Helpers/constants';

const TasksInstructor = () => {
    const [submissions, setSubmissions] = useState([]);
    const [comments, setComments] = useState({}); // State to store comments for each task submission
    const [grades, setGrades] = useState({}); // State to store grades for each task submission
    const [errors, setErrors] = useState({}); // State for validation errors
    const token = getToken(); // Use the token for authentication

    // API for getting student tasks
    useEffect(() => {
        async function fetchSubmissions() {
            const myHeaders = new Headers();
            myHeaders.append("accept", "application/json");
            myHeaders.append("Authorization", `Bearer ${token}`);
            myHeaders.append("X-CSRFToken", "pybxh0X3GfoN6t1veGE9Y4NzZEmx3TOR5iI3qC7mWtGnId7zmyI4vdo4oKCvVhYC");

            const requestOptions = {
                method: "GET",
                headers: myHeaders,
                redirect: "follow"
            };

            try {
                const response = await fetch("https://elharefaback.depi.eyouthlearning.com/api/instructor/task-submissions/", requestOptions);
                const result = await response.json();
                setSubmissions(result);
            } catch (error) {
                console.error("Error fetching task submissions:", error);
            }
        }

        fetchSubmissions();
    }, [token]);

    const handleCommentChange = (taskId, event) => {
        setComments({ ...comments, [taskId]: event.target.value });
        setErrors({ ...errors, [taskId]: "" }); // Clear errors on change
    };

    const handleGradeChange = (taskId, event) => {
        setGrades({ ...grades, [taskId]: event.target.value });
        setErrors({ ...errors, [taskId]: "" }); // Clear errors on change
    };

    // API for task comment 
    const handleSubmit = async (taskId) => {
        const comment = comments[taskId];
        const grade = grades[taskId];

        // Validation: Ensure that both comment and grade are provided
        if (!comment || !grade) {
            setErrors({ ...errors, [taskId]: "Comment and grade are required." });
            return;
        }

        const myHeaders = new Headers();
        myHeaders.append("accept", "application/json");
        myHeaders.append("Authorization", `Bearer ${token}`);
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("X-CSRFToken", "MXn99qqLQ6KZ5f8ET55r1sepCIFxiHMfsHUFi2A46k2zHZeI1X9myBPU1OVva5W0");

        const raw = JSON.stringify({
            "task_submissions": taskId,
            "comment": comment,
            "degree": parseInt(grade) // Ensure it's a number
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        try {
            const response = await fetch("https://elharefaback.depi.eyouthlearning.com/api/task-comments/", requestOptions);
            // eslint-disable-next-line 
            const result = await response.json();

            setComments({ ...comments, [taskId]: "" });
            setGrades({ ...grades, [taskId]: "" });

            // Refresh the page after successful submission
            window.location.reload();
        } catch (error) {
            console.error("Error submitting comment:", error);
        }
    };

    // Function to handle file download
    const handleFileDownload = async (fileLink) => {
        // Extract file path from the full URL
        const filePath = fileLink.substring(fileLink.lastIndexOf('/') + 1); // Extract last two parts of the URL

        const myHeaders = new Headers();
        myHeaders.append("accept", "application/json");
        myHeaders.append("Authorization", `Bearer ${token}`);
        myHeaders.append("X-CSRFToken", "VAKICaXl1NuaygCokdVWdn9Tq60CDI09BkheLM7Eh1MKa0Iss5ZRKwKoPcgAv6aU");

        const requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow"
        };

        try {
            const response = await fetch(`https://elharefaback.depi.eyouthlearning.com/api/download/${filePath}/`, requestOptions);
            const blob = await response.blob(); // Convert the response into a blob (binary data)
            const url = window.URL.createObjectURL(blob); // Create a link to the blob
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", filePath.split("/").pop()); // Extract the file name from the filePath
            document.body.appendChild(link);
            link.click();
            link.remove();
        } catch (error) {
            console.error("Error downloading file:", error);
        }
    };

    return (
        <div className={styles.tasksInstructorContainer}>
            <h1 className={styles.title}>Task Submissions</h1>

            {submissions.length > 0 ? (
                submissions.map((submission) => (
                    <div key={submission.id} className={styles.taskCard}>
                        <h2>{submission.task_name}</h2>
                        <p><strong>Description:</strong> {submission.task_description}</p>
                        <p><strong>Submitted by:</strong> {submission.student_name}</p>
                        <p><strong>Completed on:</strong> {new Date(submission.completed_on).toLocaleString()}</p>
                        <p><strong>File:</strong> <button className={styles.downloadBtn} onClick={() => handleFileDownload(submission.file)}>Download File</button></p>
                        <p><strong>URL:</strong> <a href={submission.url} target="_blank" rel="noopener noreferrer">Visit Link</a></p>

                        {/* Comment and Grade Inputs */}
                        <div className={styles.commentSection}>
                            <textarea
                                placeholder="Enter your comment"
                                value={comments[submission.id] || ""}
                                onChange={(e) => handleCommentChange(submission.id, e)}
                                className={styles.commentInput}
                            ></textarea>
                            <input
                                type="number"
                                placeholder="Enter grade"
                                value={grades[submission.id] || ""}
                                onChange={(e) => handleGradeChange(submission.id, e)}
                                className={styles.gradeInput}
                            />

                            {/* Error display */}
                            {errors[submission.id] && <p className={styles.errorText}>{errors[submission.id]}</p>}

                            <button onClick={() => handleSubmit(submission.id)} className={styles.submitButton}>
                                Submit Comment and Grade
                            </button>
                        </div>
                    </div>
                ))
            ) : (
                <p>No task submissions found.</p>
            )}
        </div>
    );
};

export default TasksInstructor;
