import React, { useState, useEffect } from 'react';
import styles from './ProfileUser.module.css';
import { API_URL, getToken } from '../../Helpers/constants';
import upWork from '../../assets/upwork.png';
import fiverr from '../../assets/fiver.png';
import khamsat from '../../assets/khmsat.png';
import nabbesh from '../../assets/nbeesh.jpg';
import tasmeemme from '../../assets/tsmem.png';
import communityIcon from '../../assets/whatsapp-873316_1280.png';
import mostaql from "../../assets/Mostaql-Logo.png";
import linkedin from "../../assets/icons8-linkedin-96.svg";
import guru from "../../assets/guru.png";
import peoplePerHour from "../../assets/peoplePerHr.png";
import ChangePasswordModal from '../../Components/ChangePassword/ChangePasswordModal';

const platformLogos = {
  Upwork: upWork,
  Fiverr: fiverr,
  Khamsat: khamsat,
  Nabbesh: nabbesh,
  Freelancer: 'https://cdn6.f-cdn.com/build/icons/fl-logo.svg',
  Ureed: 'https://ureed.com/favicon.ico',
  Tasmeemme: tasmeemme,
  Bawaba: 'https://www.albawaba.com/sites/default/themes/zing/images/favicon.ico',
  FlexJobs: 'https://www.flexjobs.com/favicon.ico',
  Mostaql: mostaql,
  Linkedin: linkedin,
  Guru: guru,
  PeoplePerHour: peoplePerHour,
  Other: 'https://via.placeholder.com/50',
};

function ProfileUser() {
  const [userData, setUserData] = useState(null);
  const [platforms, setPlatforms] = useState([]);
  const [newPlatform, setNewPlatform] = useState('');
  const [platformId, setPlatformId] = useState(''); // Dropdown selection state
  const [availablePlatforms, setAvailablePlatforms] = useState([]); // Platforms from the new API
  const [token, setToken] = useState('');
  const [error, setError] = useState('');
  const [isPasswordModalOpen, setPasswordModalOpen] = useState(false);
  const [urlError, setUrlError] = useState(''); // New state to track URL errors
  const [submitError, setSubmitError] = useState(''); // Backend error handling

  useEffect(() => {
    const dynamicToken = getToken();
    setToken(dynamicToken);
    fetchUserData(dynamicToken);
    fetchPlatformLinks(dynamicToken); // Fetch existing platform links
    fetchExternalPlatforms(dynamicToken); // Fetch platforms from the new API
  }, []);

  const fetchUserData = (token) => {
    const myHeaders = new Headers();
    myHeaders.append('accept', 'application/json');
    myHeaders.append('Authorization', `Bearer ${token}`);

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };

    fetch(`${API_URL}/accounts/profile/`, requestOptions)
      .then((response) => response.json())
      .then((result) => setUserData(result))
      .catch((error) => console.error(error));
  };

  const fetchPlatformLinks = (token) => {
    const myHeaders = new Headers();
    myHeaders.append('accept', 'application/json');
    myHeaders.append('Authorization', `Bearer ${token}`);

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };

    fetch(`${API_URL}/freelance-profile-links/`, requestOptions)
      .then((response) => response.json())
      .then((result) => setPlatforms(result))
      .catch((error) => console.error(error));
  };

  const fetchExternalPlatforms = (token) => {
    const myHeaders = new Headers();
    myHeaders.append('accept', 'application/json');
    myHeaders.append('Authorization', `Bearer ${token}`);

    fetch(`${API_URL}/platforms/`, {
      method: 'GET',
      headers: myHeaders,
    })
      .then((response) => response.json())
      .then((result) => {
        setAvailablePlatforms(result); // Populate the dropdown menu
      })
      .catch((error) =>
        console.error('Error fetching external platforms:', error)
      );
  };

  const postPlatformLink = () => {
    // Reset previous errors
    setError('');
    setUrlError('');
    setSubmitError('');

    // Validate URL before sending to backend
    if (newPlatform.trim() === '') {
      setUrlError('Platform URL is required.');
      return;
    } else if (!newPlatform.startsWith('https://')) {
      setUrlError('Platform URL must start with "https://".');
      return;
    }

    if (platformId === '') {
      setError('You must select a platform.');
      return;
    }

    const myHeaders = new Headers();
    myHeaders.append('accept', 'application/json');
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Authorization', `Bearer ${token}`);

    const raw = JSON.stringify({ url: newPlatform, platform: platformId });

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    };

    fetch(`${API_URL}/freelance-profile-links/`, requestOptions)
      .then((response) => {
        if (!response.ok) {
          return response.json().then((data) => {
            // Check for URL validation error and set the error state accordingly
            if (data.url && Array.isArray(data.url)) {
              setUrlError(data.url[0]); // Assuming the error message is in an array
            }
            throw new Error('Validation error occurred');
          });
        }
        return response.json();
      })
      .then(() => {
        setNewPlatform('');
        setPlatformId(''); // Reset dropdown
        fetchPlatformLinks(token); // Refresh the links after posting
      })
      .catch((error) => {
        setSubmitError(error.message || 'An error occurred while submitting.');
      });
  };

  return (
    <>
      <h2 className={styles.title}>User Profile</h2>
      <div className={styles.profileContainer}>
        {userData ? (
          <div className={styles.userInfo}>
            <div className={styles.userCard}>
              <p><strong>Email:</strong> {userData?.user?.email || 'N/A'}</p>
            </div>
            <div className={styles.userCard}>
              <p><strong>Phone:</strong> {userData?.phone || 'N/A'}</p>
            </div>

            <ChangePasswordModal
              isOpen={isPasswordModalOpen}
              onClose={() => setPasswordModalOpen(false)}
              token={token}
            />

            <div className={styles.userCard}>
              <p><strong>Coaching Round:</strong>{' '}
                {Array.isArray(userData?.coaching_rounds)
                  ? userData.coaching_rounds.join(', ')
                  : userData?.coaching_rounds}
              </p>
            </div>
            {userData?.job_profile_round && (
              <div className={styles.userCard}>
                <p><strong>Job Round Code:</strong>{' '}
                  {userData?.job_profile_round?.code || 'N/A'}
                </p>
                <p className={styles.communityLink}>
                  <strong>Community Link:</strong>{' '}
                  <a href={userData?.community_link || '#'} target='_blank' rel='noopener noreferrer'>
                    <img src={communityIcon} alt="Community icon" className={styles.communityLogo} />
                  </a>
                </p>
              </div>
            )}

            <div className={styles.linksSection}>
              <h1>Freelance account links:</h1>
              <ul className={styles.linkList}>
                {platforms.map((platform, index) => (
                  <li key={index} className={styles.linkCard}>
                    <a href={platform.url} target='_blank' rel='noopener noreferrer'>
                      <img
                        src={platformLogos[platform.platform_name] || platformLogos['Other']}
                        alt={platform.platform_name}
                        className={styles.platformLogo}
                        title={platform.platform_name}
                      />
                    </a>
                  </li>
                ))}
              </ul>
              {platforms.length < 3 && (
                <p className={styles.warning}>
                  You must submit at least 3 links.
                </p>
              )}
            </div>
          </div>
        ) : (
          <p>Loading user data...</p>
        )}

        <div className={styles.platformsSection}>
          <h3>Add Your Accounts on Freelance Platforms</h3>
          <div className={styles.inputContainer}>
            <input
              type='text'
              value={newPlatform}
              onChange={(event) => {
                setNewPlatform(event.target.value);
                setError('');
                setUrlError('');
                setSubmitError('');
              }}
              placeholder='Enter freelance platform link'
              className={styles.inputField}
            />
            <select
              value={platformId}
              onChange={(event) => setPlatformId(event.target.value)}
              className={styles.selectField}
            >
              <option value=''>Select Platform</option>
              {availablePlatforms.map((platform) => (
                <option key={platform.id} value={platform.id}>
                  {platform.name}
                </option>
              ))}
            </select>
          </div>

          <button onClick={postPlatformLink} className={styles.addButton}>
            Submit Link
          </button>
          {urlError && <p className={styles.error}>{urlError}</p>}
          {error && <p className={styles.error}>{error}</p>}
          {submitError && <p className={styles.error}>{submitError}</p>}
        </div>
      </div>
    </>
  );
}

export default ProfileUser;
