import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import styles from './FreelanceTracking.module.css'; // Assuming you have a CSS file for styling similar to StudentDashboard
import { API_URL, getToken } from '../../Helpers/constants';
import { Link } from 'react-router-dom';

function FreelanceTracking() {
  const [jobsData, setJobsData] = useState([]); // Initialize as an empty array
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1); // Tracks the current page
  const [totalPages, setTotalPages] = useState(1); // Total number of pages
  // eslint-disable-next-line
  const [totalCount, setTotalCount] = useState(0); // Total count of items
  // eslint-disable-next-line
  const [pageSize, setPageSize] = useState(10); // Items per page
  const token = getToken();

  const fetchJobs = (page) => {
    setLoading(true);
    const myHeaders = new Headers();
    myHeaders.append("accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("X-CSRFToken", "jzgiYsPWVRGJMuZK1nrGSgSKhEPfX3r3sXigVRmSts2PpgEjoJhPfq1F7s7nA3GR");

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${API_URL}/student-jobs/?page=${page}&page_size=${pageSize}`, requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.results) {
          setJobsData(result.results); // Safely set jobs data
        } else {
          setJobsData([]); // Set as an empty array if no results field
        }
        setTotalPages(Math.ceil(result.count / pageSize)); // Total pages calculated based on total count
        setTotalCount(result.count); // Total items
        setLoading(false);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchJobs(currentPage);
    // eslint-disable-next-line
  }, [currentPage, pageSize]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  // const handlePageSizeChange = (e) => {
  //   setPageSize(parseInt(e.target.value, 10));
  //   setCurrentPage(1); // Reset to first page on page size change
  // };

  const renderPagination = () => {
    const paginationRange = 5;
    const pages = [];

    if (currentPage > 1) {
      pages.push(
        <button key={1} onClick={() => handlePageChange(1)} className={currentPage === 1 ? styles.active : ''}>
          1
        </button>
      );
    }

    if (currentPage > paginationRange) {
      pages.push(<span key="ellipsis-start">...</span>);
    }

    for (let i = Math.max(2, currentPage - 2); i <= Math.min(totalPages - 1, currentPage + 2); i++) {
      pages.push(
        <button key={i} onClick={() => handlePageChange(i)} className={currentPage === i ? styles.active : ''}>
          {i}
        </button>
      );
    }

    if (currentPage + 2 < totalPages - 1) {
      pages.push(<span key="ellipsis-end">...</span>);
    }

    if (currentPage !== totalPages && totalPages > 1) {
      pages.push(
        <button key={totalPages} onClick={() => handlePageChange(totalPages)} className={currentPage === totalPages ? styles.active : ''}>
          {totalPages}
        </button>
      );
    }

    return pages;
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  return (
    <div className={styles.container}>
      <TableContainer component={Paper}>
        <Table aria-label="freelance tracking table">
          <TableHead>
            <TableRow>
              <TableCell><strong>ID</strong></TableCell>
              <TableCell><strong>Name</strong></TableCell>
              <TableCell><strong>Job Count</strong></TableCell>
              <TableCell><strong>Total Income</strong></TableCell>
              <TableCell><strong>Action</strong></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {jobsData.length > 0 ? (
              jobsData.map((job) => (
                <TableRow key={job.id}>
                  <TableCell>{job.id}</TableCell>
                  <TableCell>{job.name}</TableCell>
                  <TableCell>{job.job_count}</TableCell>
                  <TableCell>{job.total_income}</TableCell>
                  <TableCell><Link to={`/student-profile/${job.id}`}>View profile</Link> </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={5} style={{ textAlign: 'center' }}>No jobs found</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Pagination Controls */}
      <div className={styles.pagination}>
        {renderPagination()}
      </div>

      <div style={{ textAlign: 'center', marginTop: '10px' }}>
        Page {currentPage} of {totalPages}
      </div>
    </div>
  );
}

export default FreelanceTracking;
