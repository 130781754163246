import React, { useState, useEffect } from 'react';
import { API_URL, getToken } from '../../Helpers/constants';
import styles from './GetALLMeetings.module.css';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';

function GetALLMeetings() {
  const [meetings, setMeetings] = useState([]);
  const [selectedMeeting, setSelectedMeeting] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const token = getToken();

  useEffect(() => {
    const myHeaders = new Headers();
    myHeaders.append("accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("X-CSRFToken", `CRauAZd077qDFDFrKPpFQA70HmrDRt55nExXKG6Z2OuniiVD1NtaHwZkTnAKbdM6`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${API_URL}/meetings/`, requestOptions)
      .then((response) => response.json())
      .then((result) => { setMeetings(result); console.log('result', result); })
      .catch((error) => console.error('Error fetching data:', error));
  }, [token]);

  const openModal = (meeting) => {
    setSelectedMeeting(meeting);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setSelectedMeeting(null);
  };

  return (
    <div>
      <table border="1" cellPadding="10" cellSpacing="0" className={styles.tableWidth}>
        <thead>
          <tr>
            <th>Instructor ID</th>
            <th>Student ID</th>
            <th>Title</th>
            <th>Date</th>
            <th>Start Time</th>
            <th>End Time</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {meetings.map((meeting, i) => (
            <tr key={i}>
              <td>{meeting.instructor}</td>
              <td>{meeting.feedbacks.length > 0 ? meeting.feedbacks[0].student : '_'}</td>
              <td>{meeting.title}</td>
              <td>{meeting.date}</td>
              <td>{meeting.start_time}</td>
              <td>{meeting.end_time}</td>
              <td>
                {/* Single button to show both attendance and feedback */}
                <Button variant="contained" color="primary" onClick={() => openModal(meeting)}>Show Details</Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Combined Attendance & Feedback Modal */}
      <Dialog open={isModalOpen} onClose={closeModal} maxWidth="md" fullWidth>
        <DialogTitle>Attendance & Feedback</DialogTitle>
        <DialogContent>
          {selectedMeeting ? (
            <div>
              <table border="1" cellPadding="10" cellSpacing="0" style={{ width: '100%' }}>
                <thead>
                  <tr>
                    <th>Instructor Feedback</th>
                    <th>Student Feedback</th>
                    <th>Student Rating</th>
                    <th>Joined</th>
                  </tr>
                </thead>
                <tbody>
                  {/* Combine attendance and feedback data in one row */}
                  {selectedMeeting.attendances.map((attendance, index) => (
                    <tr key={index}>
                      <td>{attendance.instructor_feedback || 'No Instructor Feedback'}</td>
                      {/* Display corresponding feedback */}
                      {selectedMeeting.feedbacks[index] ? (
                        <>
                          <td>{selectedMeeting.feedbacks[index].feedback || 'No Feedback'}</td>
                          <td>{selectedMeeting.feedbacks[index].rating || 'No Rating'}</td>
                        </>
                      ) : (
                        <>
                          <td>No Feedback</td>
                          <td>No Rating</td>
                        </>
                      )}
                      <td>{attendance.joined ? 'Yes' : 'No'}</td>

                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <p>No data available.</p>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModal} color="primary">Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default GetALLMeetings;
