
import React, { useEffect, useState } from 'react';
import { getToken } from '../../Helpers/constants';
import { useParams } from 'react-router-dom';
import styles from './WebinarAttendanceInfo.module.css'; // Importing the CSS module
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

function WebinarAttendanceInfo() {
    const [attendees, setAttendees] = useState([]);
    // const [attendList, setAttendList] = useState([]);
    // const [notAttendList, setNotAttendList] = useState([]);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const token = getToken();
    const { id } = useParams();

    useEffect(() => {
        fetchAttendees();
        // eslint-disable-next-line
    }, [id, token]);

    const fetchAttendees = () => {
        const myHeaders = new Headers();
        myHeaders.append("accept", "application/json");
        myHeaders.append("Authorization", `Bearer ${token}`);
        myHeaders.append("X-CSRFToken", "Te8erCmOuvoTZyJKwIIqLigGhfuAD6DvE1vHBjfNpcsDCdZWNGMVCe80tgDHXQkw");

        const requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow"
        };

        fetch(`https://elharefaback.depi.eyouthlearning.com/api/webinars/attendance/webinar/${id}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                setAttendees(result);
            })
            .catch(error => console.error('Error fetching data:', error));
    };



    const handleStatusChange = (attendeeId, status) => {
        const myHeaders = new Headers();
        myHeaders.append("accept", "application/json");
        myHeaders.append("Authorization", `Bearer ${token}`);
        myHeaders.append("X-CSRFToken", "2Wlr6tQbvfWPZ7AvBEoK7vbl44ZDDdJ4FEN0p4gpjWwUuxJE721PzbVLNVoDlAsq");

        const requestOptions = {
            method: "PUT",
            headers: myHeaders,
            redirect: "follow"
        };

        fetch(`https://elharefaback.depi.eyouthlearning.com/api/webinars/attendance/webinar/${id}/student/${attendeeId}/`, requestOptions)
            .then((response) => response.text())
            .then((result) => {
                // Show success message after updating the status
                setOpenSnackbar(true);
                // Optionally fetch the updated list of attendees
                fetchAttendees();
            })
            .catch((error) => console.error('Error updating attendance:', error));
    };

    // const resetLists = () => {
    //     setAttendList([]);
    //     setNotAttendList([]);
    // };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };

    return (
        <div>
            <h1 className={styles.webinarList}>Webinar Attendance List</h1>

            {/* Success Snackbar */}
            <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                <MuiAlert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
                    Attendance status updated successfully!
                </MuiAlert>
            </Snackbar>

            <table border="1" cellPadding="10" cellSpacing="0">
                <thead>
                    <tr>
                        <th>Student ID</th>
                        <th>Email</th>
                        <th>webinar name</th>
                        <th>joined</th>
                        <th>Change status </th>

                    </tr>
                </thead>
                <tbody>
                    {attendees.length > 0 ? (
                        attendees.map((attendee) => (
                            <tr key={attendee.student}>
                                <td>{attendee.student}</td>
                                <td>{attendee.student_email}</td>
                                <td>{attendee.webinar_name}</td>
                                <td>{attendee.joined ? "true" : "false"}</td>
                                <td>
                                    <select
                                        onChange={(e) => handleStatusChange(attendee.student, e.target.value)}
                                        defaultValue={attendee.joined ? "attend" : "not_attend"}
                                    >
                                        <option value="" disabled>Select Status</option>
                                        <option value="attend">Attend</option>
                                        <option value="not_attend">Not Attend</option>
                                    </select>
                                </td>

                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="4">No attendees found.</td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
}

export default WebinarAttendanceInfo;
