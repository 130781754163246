import React from 'react';
import logo1 from '../../assets/AGEN.png';
import logo2 from '../../assets/MCEN.png';
import logo3 from '../../assets/depiEN.png';
import logo4 from '../../assets/logo2.png';
import { NavLink } from 'react-router-dom';
import styles from './InstructorNavbar.module.css';
import SubNavbar from '../SubNavbar/SubNavbar';


const InstructorNavbar = () => {
    return (
        <>
            <nav className={styles.navbar}>
                <div className={styles.logoContainer}>
                    <img src={logo1} alt="Logo 1" className={styles.logo} />
                    <img src={logo2} alt="Logo 2" className={styles.logo2} />
                </div>
                <div className={styles.navLink}>
                    <NavLink
                        to="/instructor-profile"
                        className={({ isActive }) => isActive ? styles.active : undefined}
                    >
                        Profile
                    </NavLink>
                    <NavLink
                        to="/instructor-meeting"
                        className={({ isActive }) => isActive ? styles.active : undefined}
                    >
                        Coaching sessions
                    </NavLink>
                    <NavLink
                        to="/instructor-tasks"
                        className={({ isActive }) => isActive ? styles.active : undefined}
                    >
                        Tasks
                    </NavLink>
                </div>
                <div className={styles.logoContainer}>
                    <img src={logo3} alt="Logo 3" className={styles.logo} />
                    <a  rel='noreferrer'>
                        <img src={logo4} alt="Logo 4" className={styles.logo1} />
                    </a>
                </div>
            </nav>
            <SubNavbar />
        </>
    );
};

export default InstructorNavbar;