import { useEffect } from "react";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
  useNavigate,
} from "react-router-dom";
import InstructorNavbar from "./Components/InstructorNavbar/InstructorNavbar";
import LoginPage from "./Components/Login/Login";
import MeetingPage from "./Components/Meeting/Meeting";
import MeetingInstructor from "./Components/MeetingInstructor/MeetingInstructor";
import MeNavbar from "./Components/MeNavbar/MeNavbar";
import Navbar from "./Components/Navbar/Navbar";
import StaffNavbar from "./Components/StaffNavbar/StaffNavbar";
import TasksPage from "./Components/Tasks/Tasks";
import TasksInstructor from "./Components/TasksInstructor/TasksInstructor";
import WebinarPage from "./Components/Webinar/Webinar";
import { API_URL, getRole, getToken } from "./Helpers/constants";
import FreelanceOpp from "./Pages/FreelanceOpp/FreelanceOpp";
import InstructorDashboard from "./Pages/InstructorDashboard/InstructorDashboard";
import InstructorDashboardPreview from "./Pages/InstructorDashboardPreview/InstructorDashboardPreview";
import ProfileInstructor from "./Pages/ProfileInstructor/ProfileInstructor";
import ProfileUser from "./Pages/ProfileUser/ProfileUser";
import StudentDashboard from "./Pages/StudentDashboard/StudentDashboard";
import StudentDashboardPreview from "./Pages/StudentDashboardPreview/StudentDashboardPreview";
import StudentProfileStaffPreview from "./Pages/StudentProfileStaffPreview/StudentProfileStaffPreview";
import ControlPanel from "./Pages/ControlPanel/ControlPanel";
import Unauthorized from "./Components/Unauthorized/Unauthorized";
import Footer from "./Components/Footer/Footer";
import LiveTracking from './Pages/LiveTracking/LiveTracking';
import FreelanceTracking from './Pages/FreelanceTracking/FreelanceTracking';

// Utility function to check token expiration
const isTokenExpired = (token) => {
  if (!token) return true;
  const tokenPayload = JSON.parse(atob(token.split(".")[1]));
  const expirationTime = tokenPayload.exp * 1000; // Convert to milliseconds
  return Date.now() > expirationTime;
};

// Function to handle logout
const logout = (navigate) => {
  localStorage.removeItem("access_token");
  localStorage.removeItem("refresh_token");
  localStorage.removeItem("role");
  navigate("/login");
};

// Function to refresh token
const refreshToken = async (navigate) => {
  const refreshTokenValue = localStorage.getItem("refresh_token");
  if (!refreshTokenValue || isTokenExpired(refreshTokenValue)) {
    logout(navigate); // If refresh token is missing or expired, logout
    return;
  }

  const myHeaders = new Headers();
  myHeaders.append("accept", "application/json");
  myHeaders.append("Content-Type", "application/json");

  const raw = JSON.stringify({ refresh: refreshTokenValue });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
  };

  try {
    const response = await fetch(
      `${API_URL}/accounts/token/refresh/`,
      requestOptions
    );
    const result = await response.json();

    if (response.ok) {
      localStorage.setItem("access_token", result.access);
      localStorage.setItem("refresh_token", result.refresh);
    } else {
      console.error("Failed to refresh token:", result);
      logout(navigate); // Logout if refreshing fails
    }
  } catch (error) {
    console.error("Error refreshing token:", error);
    logout(navigate); // Logout on any error
  }
};

// ProtectedRoute Component to handle role-based access
function ProtectedRoute({ children, allowedRoles }) {
  const role = getRole(); // Get current user's role
  const navigate = useNavigate();
  const token = getToken(); // Get the current access token

  useEffect(() => {
    // Check if the token is expired
    if (isTokenExpired(token)) {
      refreshToken(navigate); // Try to refresh the token if expired
    }

    if (!token) {
      navigate("/login"); // Redirect to login if no token
    }
  }, [token, navigate]);

  // If the user's role is not allowed, show Unauthorized page
  if (!allowedRoles.includes(role)) {
    return <Unauthorized />;
  }

  return children; // Return the content if authorized
}

function AppContent() {
  const token = getToken();
  const navigate = useNavigate();
  const role = getRole();

  useEffect(() => {
    if (!token && window.location.pathname !== "/login") {
      navigate("/login");
    }
  }, [token, navigate]);

  return (
    <div className="contentheight">
      <Routes>
        <Route path="/login" element={<LoginPage />} />

        <Route
          path="/"
          element={
            role === "student" ? (
              <Navigate to="/user-profile" />
            ) : role === "instructor" ? (
              <Navigate to="/instructor-profile" />
            ) : role === "me" ? (
              <Navigate to="/instructors-dashboard" />
            ) : role === "staff" ? (
              <Navigate to="/student-dashboard-preview" />
            ) : (
              <Navigate to="/login" />
            ) // Default to login if no role
          }
        />

        {/* Routes accessible by students */}
        <Route
          path="/user-profile"
          element={
            <ProtectedRoute allowedRoles={["student"]}>
              <ProfileUser />
            </ProtectedRoute>
          }
        />
        <Route
          path="/freelance"
          element={
            <ProtectedRoute allowedRoles={["student"]}>
              <FreelanceOpp />
            </ProtectedRoute>
          }
        />
        <Route
          path="/tasks"
          element={
            <ProtectedRoute allowedRoles={["student"]}>
              <TasksPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/meeting"
          element={
            <ProtectedRoute allowedRoles={["student"]}>
              <MeetingPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/webinar"
          element={
            <ProtectedRoute allowedRoles={["student"]}>
              <WebinarPage />
            </ProtectedRoute>
          }
        />

        {/* Routes accessible by instructors */}
        <Route
          path="/instructor-profile"
          element={
            <ProtectedRoute allowedRoles={["instructor"]}>
              <ProfileInstructor />
            </ProtectedRoute>
          }
        />
        <Route
          path="/instructor-tasks"
          element={
            <ProtectedRoute allowedRoles={["instructor"]}>
              <TasksInstructor />
            </ProtectedRoute>
          }
        />
        <Route
          path="/instructor-meeting"
          element={
            <ProtectedRoute allowedRoles={["instructor"]}>
              <MeetingInstructor />
            </ProtectedRoute>
          }
        />

        {/* Routes accessible by "me" */}
        <Route
          path="/instructors-dashboard"
          element={
            <ProtectedRoute allowedRoles={["me"]}>
              <InstructorDashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/student-dashboard"
          element={
            <ProtectedRoute allowedRoles={["me"]}>
              <StudentDashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/live-tracking-me"
          element={
            <ProtectedRoute allowedRoles={["me"]}>
              <LiveTracking />
            </ProtectedRoute>
          }
        />


        {/* Routes accessible by staff */}
        <Route
          path="/instructors-dashboard-preview"
          element={
            <ProtectedRoute allowedRoles={["staff"]}>
              <InstructorDashboardPreview />
            </ProtectedRoute>
          }
        />
        <Route
          path="/student-dashboard-preview"
          element={
            <ProtectedRoute allowedRoles={["staff"]}>
              <StudentDashboardPreview />
            </ProtectedRoute>
          }
        />
        <Route
          path="/student-profile/:id"
          element={
            <ProtectedRoute allowedRoles={["staff", "me"]}>
              <StudentProfileStaffPreview />
            </ProtectedRoute>
          }
        />
        <Route
          path="/control-panel/*"
          element={
            <ProtectedRoute allowedRoles={["staff"]}>
              <ControlPanel />
            </ProtectedRoute>
          }
        />
        <Route
          path="/live-tracking"
          element={
            <ProtectedRoute allowedRoles={["staff"]}>
              <LiveTracking />
            </ProtectedRoute>
          }
        />
        <Route
          path="/freelance-tracking"
          element={
            <ProtectedRoute allowedRoles={["staff", "me"]}>
              <FreelanceTracking />
            </ProtectedRoute>
          }
        />
        {/* Unauthorized route */}
        <Route path="/unauthorized" element={<Unauthorized />} />
      </Routes>

    </div>
  );
}

function App() {
  const role = getRole();

  const renderNavbar = () => {
    if (role === "me") {
      return <MeNavbar />;
    } else if (role === "instructor") {
      return <InstructorNavbar />;
    } else if (role === "student") {
      return <Navbar />;
    } else if (role === "staff") {
      return <StaffNavbar />;
    }
    return <Navbar />; // Default to student navbar if role is not defined
  };

  return (
    <Router>
      {renderNavbar()}
      <AppContent />
      <Footer />

    </Router>
  );
}

export default App;
