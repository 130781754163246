import React, { useEffect, useState } from "react";
import { API_URL, getToken } from "../../Helpers/constants";
import Modal from "../../Components/Modal/Modal";
import styles from "./InstructorDashboardPreview.module.css";

function InstructorDashboardPreview() {
  const [instructors, setInstructors] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [ordering, setOrdering] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [selectedInstructor, setSelectedInstructor] = useState(null);
  const [urlError, setUrlError] = useState("");

  const [meetingDetails, setMeetingDetails] = useState({
    title: "",
    date: "",
    startTime: "",
    endTime: "",
    meetingUrl: "",
  });
  const [showPopover, setShowPopover] = useState(false); // State to handle popover visibility
  const token = getToken();

  useEffect(() => {
    const fetchInstructors = async () => {
      const myHeaders = new Headers();
      myHeaders.append("accept", "application/json");
      myHeaders.append("Authorization", `Bearer ${token}`);
      myHeaders.append(
        "X-CSRFToken",
        "dbulTVCCssdH35VRb2jUALKF7FHDu8ib2o2wyfbUuMzhZXQ0PLSvAgBOTQSwdOYi"
      );

      const url = new URL(
        `${API_URL}/admin/instructors/`
      );
      url.searchParams.append("coaching_rounds__code", searchTerm);
      url.searchParams.append("ordering", ordering);
      url.searchParams.append("page", page);
      url.searchParams.append("page_size", pageSize);

      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      try {
        const response = await fetch(url, requestOptions);
        const data = await response.json();
        setInstructors(data.results);
        setTotalCount(data.count);
      } catch (error) {
        console.error("Error fetching instructors:", error);
      }
    };

    fetchInstructors();
  }, [searchTerm, ordering, page, pageSize, token]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setPage(1); // Reset to the first page when search changes
  };

  const handleOrderingChange = (e) => {
    setOrdering(e.target.value);
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handlePageSizeChange = (e) => {
    setPageSize(e.target.value);
  };

  const openModal = (instructor) => {
    setSelectedInstructor(instructor);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setMeetingDetails({
      title: "",
      date: "",
      startTime: "",
      endTime: "",
      meetingUrl: "",
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setMeetingDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    // Reset previous error
    setUrlError("");

    // Validate the meeting URL to ensure it starts with 'https://'
    if (!meetingDetails.meetingUrl.startsWith("https://")) {
      setUrlError("The meeting URL must start with 'https://'. Please enter a valid URL.");
      return; // Prevent form submission if validation fails
    }

    const selectedDate = new Date(meetingDetails.date);
    const currentDate = new Date();

    // Check if the selected date is today
    const isToday = selectedDate.toDateString() === currentDate.toDateString();

    if (isToday) {
      const selectedStartTime = meetingDetails.startTime.split(":");
      const currentHours = currentDate.getHours();
      const currentMinutes = currentDate.getMinutes();

      // Validate start time for today
      if (
        parseInt(selectedStartTime[0]) < currentHours ||
        (parseInt(selectedStartTime[0]) === currentHours &&
          parseInt(selectedStartTime[1]) < currentMinutes)
      ) {
        alert("The selected start time is in the past. Please select a valid time.");
        return;
      }
    }

    // Validate that the end time is after the start time
    const selectedStartTime = meetingDetails.startTime.split(":");
    const selectedEndTime = meetingDetails.endTime.split(":");

    if (
      parseInt(selectedEndTime[0]) < parseInt(selectedStartTime[0]) ||
      (parseInt(selectedEndTime[0]) === parseInt(selectedStartTime[0]) &&
        parseInt(selectedEndTime[1]) <= parseInt(selectedStartTime[1]))
    ) {
      alert("The end time should be after the start time.");
      return;
    }

    // Proceed with submitting the form if all validations pass
    const myHeaders = new Headers();
    myHeaders.append("accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "X-CSRFToken",
      "PQAfaQNM4rdxy6hBx8unyJEfyxnHBblavA7LjsX5kFv7aQnFF0yi5SfKXDDFtzvV"
    );

    const raw = JSON.stringify({
      instructor: selectedInstructor.id,
      title: meetingDetails.title,
      date: meetingDetails.date,
      start_time: meetingDetails.startTime,
      end_time: meetingDetails.endTime,
      meeting_url: meetingDetails.meetingUrl,
      is_reserved: false,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      const response = await fetch(`${API_URL}/meetings/`, requestOptions);
      // eslint-disable-next-line
      const result = await response.json();
      closeModal();

      // Show popover after successful creation
      setShowPopover(true);

      // Hide the popover after 3 seconds
      setTimeout(() => {
        setShowPopover(false);
      }, 3000);
    } catch (error) {
      console.error("Error creating meeting:", error);
    }
  };


  const renderPagination = () => {
    const totalPages = Math.ceil(totalCount / pageSize);
    const pages = [];

    // Always show the first page
    pages.push(
      <button
        key={1}
        onClick={() => handlePageChange(1)}
        className={page === 1 ? styles.active : ""}
      >
        1
      </button>
    );

    // Show the dots if the current page is greater than 4
    if (page > 4) {
      pages.push(<span key="start-dots">.....</span>);
    }

    // Calculate start and end pages for the middle section
    const startPage = Math.max(2, page - 2);
    const endPage = Math.min(totalPages - 1, page + 2);

    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <button
          key={i}
          onClick={() => handlePageChange(i)}
          className={page === i ? styles.active : ""}
        >
          {i}
        </button>
      );
    }

    // Show the dots if we're not near the end
    if (page < totalPages - 3) {
      pages.push(<span key="end-dots">...</span>);
    }

    // Always show the last page
    if (totalPages > 1) {
      pages.push(
        <button
          key={totalPages}
          onClick={() => handlePageChange(totalPages)}
          className={page === totalPages ? styles.active : ""}
        >
          {totalPages}
        </button>
      );
    }

    return pages;
  };

  return (
    <div className={styles.container}>
      <div className={styles.controls}>
        <input
          type="text"
          placeholder="Search by coaching rounds code..."
          value={searchTerm}
          onChange={handleSearchChange}
        />
        <select value={ordering} onChange={handleOrderingChange}>
          <option value="">Order by</option>
          <option value="total_meetings">Total Coaching Sessions</option>
          <option value="free_slots">Free Slots</option>
        </select>
        <select value={pageSize} onChange={handlePageSizeChange}>
          <option value={5}>5 per page</option>
          <option value={10}>10 per page</option>
          <option value={20}>20 per page</option>
        </select>
      </div>

      <table className={styles.table}>
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Phone</th>
            <th>Coaching Rounds</th>
            <th>Total Coaching Sessions</th>
            <th>Free Slots</th>
            <th>Average Rating</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {instructors?.map((instructor) => (
            <tr key={instructor.id}>
              <td>{instructor.id}</td>
              <td>{instructor.name || "N/A"}</td>
              <td>{instructor.phone || "N/A"}</td>
              <td>{instructor.coaching_rounds.join(", ")}</td>
              <td>{instructor.total_meetings}</td>
              <td>{instructor.free_slots}</td>
              <td>{instructor.average_rating}</td>
              <td>
                <button
                  className={styles.createMeetingBtn}
                  onClick={() => openModal(instructor)}
                >
                  Create Coaching Session
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className={styles.pagination}>{renderPagination()}</div>

      {/* Modal for creating a meeting */}
      <Modal show={showModal} onClose={closeModal}>
        <form onSubmit={handleFormSubmit}>
          <h2 className={styles.modalHeader}>Create Coaching Session</h2>
          <div className={styles.formGroup}>
            <label>Coach ID</label>
            <input type="text" value={selectedInstructor?.id || ""} disabled />
          </div>
          <div className={styles.formGroup}>
            <label>Title</label>
            <input
              type="text"
              name="title"
              value={meetingDetails.title}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className={styles.formGroup}>
            <label>Date</label>
            <input
              type="date"
              name="date"
              value={meetingDetails.date}
              onChange={handleInputChange}
              min={new Date().toISOString().split("T")[0]} // Disable past dates
              required
            />
          </div>
          <div className={styles.formGroup}>
            <label>Start Time</label>
            <input
              type="time"
              name="startTime"
              value={meetingDetails.startTime}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className={styles.formGroup}>
            <label>End Time</label>
            <input
              type="time"
              name="endTime"
              value={meetingDetails.endTime}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className={styles.formGroup}>
            <label>Coaching Session URL</label>
            <input
              type="url"
              name="meetingUrl"
              value={meetingDetails.meetingUrl}
              onChange={handleInputChange}
              required
            />
            {urlError && <span className={styles.error}>{urlError}</span>}
          </div>

          <button type="submit" className={styles.meetingSubmitBtn}>
            Create Coaching Session
          </button>
        </form>
      </Modal>

      {/* Popover for successful meeting creation */}
      {showPopover && (
        <div className={styles.popover}>
          Congratulations! Coaching Session created successfully.
        </div>
      )}
    </div>
  );
}

export default InstructorDashboardPreview;
